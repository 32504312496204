import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselApi,
} from "@/components/ui/carousel";
import Button from "../ui/button";
import Link from "@/components/ui/Link";
import { Star } from "lucide-react";
import { testimonials } from "./constants";
import { sanitizeHTML } from "@/lib/utils";
import React from "react";

interface PatientsSayingData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  button?: {
    label?: string;
    href?: string;
  };
  content_collection?: {
    id: string;
    reviews_id: {
      rating: number;
      author: string;
      comment: string;
    };
  }[];
}

export const fields = [
  "heading.text",
  "heading.title",

  "subheading.text", 
  "subheading.title",

  "button.label",
  "button.href",

  "content_collection.id",
  "content_collection.reviews_id.rating",
  "content_collection.reviews_id.author",
  "content_collection.reviews_id.comment",


] as const;

interface PatientsSayingProps {
  data?: PatientsSayingData;
}

export default function PatientsSaying({ data }: PatientsSayingProps) {
  const [api, setApi] = React.useState<CarouselApi>();
  const [current, setCurrent] = React.useState(0);

  React.useEffect(() => {
    if (!api) return;

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap());
    });
  }, [api]);

  const totalSlides = data?.content_collection?.length || 0;

  return (
		<section id='patients-saying-new' className='py-8 bg-[#F4F4F4]'>
			<div className='container max-w-[1200px]'>
				<div className='text-center mb-8'>
					<h2 className='text-[40px] font-freightBig font-normal'>
						{data?.heading?.title || 'What our patients say about us'}
					</h2>
					<div className='mt-3 flex flex-col items-center justify-center gap-1'>
						<span className='text-sm font-aileron'>
							{/* {data?.subheading?.title || 'Verified Google'} */}
							{'Verified Google'}
						</span>
						<span className='text-sm font-aileron mb-2'>Review</span>
						<div className='flex gap-1'>
							{[...Array(5)].map((_, i) => (
								<Star key={i} className='w-4 h-4 fill-current text-black' />
							))}
						</div>
					</div>
				</div>

				<div className='relative w-full bg-[#665e5d]'>
					{totalSlides <= 1 ? (
						// Single review box
						<div className='min-h-[300px] flex items-center justify-center px-4'>
							<div className='max-w-[1000px] mx-auto px-4 md:px-[84px] py-8 md:py-[60px]'>
								<div className='box'>
									<p className='text-white text-center text-base font-light font-aileron leading-normal'>
										{data?.content_collection?.[0]?.reviews_id?.comment || ''}
									</p>
								</div>
							</div>
						</div>
					) : (
						// Multiple reviews carousel
						<>
							<Carousel
								opts={{
									align: 'center',
									loop: true,
								}}
								setApi={setApi}
							>
								<CarouselContent>
									{data?.content_collection?.map((collection, index) => {
										const comment = collection?.reviews_id?.comment || ''
										return (
											<CarouselItem key={index} className='basis-full'>
												<div className='min-h-[300px] flex items-center justify-center px-4'>
													<div className='max-w-[1000px] mx-auto px-4 md:px-[84px] py-8 md:py-[60px]'>
														<div className='max-h-[200px] overflow-y-auto custom-scrollbar hover:overflow-y-scroll'>
															<p className='text-white text-center text-base font-aileron leading-normal [text-shadow:_0px_1px_3px_rgb(0_0_0_/_0.30)]'>
																{comment}
															</p>
														</div>
													</div>
												</div>
											</CarouselItem>
										)
									})}
								</CarouselContent>
							</Carousel>

							<div className='flex justify-center gap-2.5 pb-8'>
								{[...Array(totalSlides)].map((_, i) => (
									<button
										key={i}
										onClick={() => api?.scrollTo(i)}
										className={`w-[7px] h-[7px] rounded-[7px] transition-opacity ${
											i === current ? 'bg-white' : 'bg-white/50'
										}`}
										aria-label={`Go to slide ${i + 1}`}
									/>
								))}
							</div>
						</>
					)}
				</div>

				<div className='flex justify-center my-8'>
					{data?.button && (
						<Button
							asChild
							variant='outline'
							className='inline-flex items-center justify-center px-[35px] py-[15px] text-[12px] font-plusJakarta font-bold uppercase tracking-[0.1em] text-[#332f2c] border border-[#b2b2b2] rounded-full transition-all duration-300 hover:bg-[#645e5e] hover:text-white hover:border-black'
						>
							<Link href={data?.button?.href || '#'}>
								{data?.button?.label || 'READ MORE TESTIMONIALS'}
							</Link>
						</Button>
					)}
				</div>
			</div>
		</section>
	)
}

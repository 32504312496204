"use client";

import Link from "@/components/ui/Link";
import { useEffect, useState } from "react";
import { sanitizeHTML } from "@/lib/utils";

interface PageSectionNavigationData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  anchors?: {
    anchor_id: {
      anchor_id: string;
      label: string;
    };
  }[];
}

export const fields = [
  "heading.text",
  "heading.title",
  "subheading.text",
  "subheading.title",
  "anchors.anchor_id.anchor_id",
  "anchors.anchor_id.label",
] as const;

interface PageSectionNavigationProps {
  data: PageSectionNavigationData;
}

export default function PageSectionNavigation({
  data
}: PageSectionNavigationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktopOpen, setIsDesktopOpen] = useState(true);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [selectedSection, setSelectedSection] = useState<string>("");


  useEffect(() => {
    const updateHeaderHeight = () => {
      const header = document.querySelector("header");
      if (header) {
        setHeaderHeight(header.offsetHeight);
      }
    };

    // Set scroll margin for anchor elements on mobile
    const setScrollMargin = () => {
      const anchors = document.querySelectorAll('[id]');
      anchors.forEach(anchor => {
        (anchor as HTMLElement).style.scrollMarginTop = window.innerWidth < 768 ? '120px' : '0';
      });
    };

    updateHeaderHeight();
    setScrollMargin();

    window.addEventListener("resize", () => {
      updateHeaderHeight();
      setScrollMargin();
    });
    return () => window.removeEventListener("resize", updateHeaderHeight);
  }, []);

  return (
    <section
      id="page-section-navigation"
      className="w-full md:container sticky top-[48px] md:top-0 md:relative z-[11] md:z-auto"
    >
      <div id="anchors" className="relative w-full">
        <div className="w-full">
          <div className="md:px-0 px-5 py-5 md:pb-6 w-full bg-black md:bg-transparent">
            <div className="flex flex-col md:flex-row w-full md:justify-between">
              <span className="text-white md:text-black text-lg font-medium">
                {data?.heading?.title ? (
                  <div dangerouslySetInnerHTML={sanitizeHTML(data.heading.title)} />
                ) : (
                  "JUMP TO SECTION:"
                )}
              </span>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-white md:text-black text-lg md:hidden w-fit relative pr-6"
              >
                {selectedSection || (data?.heading?.text ? (
                  <div dangerouslySetInnerHTML={sanitizeHTML(data.heading.text)} />
                ) : (
                  "Select Section"
                ))}
                <span className="absolute right-0 top-1/2 -mt-1 border-[6px] border-transparent border-t-white md:border-t-black"></span>
              </button>
              <button
                onClick={() => setIsDesktopOpen(!isDesktopOpen)}
                className="hidden md:flex items-center text-black text-sm font-medium cursor-pointer"
              >
                {isDesktopOpen ? "Hide sections" : "Show sections"}
                <span className={`ml-2 transition-transform duration-200 ${isDesktopOpen ? 'rotate-180' : ''}`}>
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6 6L11 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div className={`${isOpen ? 'absolute' : 'hidden'} ${isDesktopOpen ? 'md:block' : 'md:hidden'} left-0 top-full bg-black md:bg-transparent p-6 md:p-0 min-w-[285px] md:min-w-full z-10 transition-all duration-300`}>
            <ul className="space-y-2 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-4 md:border-b md:border-black md:pb-6">
              {data?.anchors?.map((link, index) => (
                <li key={index} className="md:w-auto w-full block">
                  <Link
                    href={link.anchor_id.anchor_id}
                    onClick={() => {
                      setSelectedSection(link.anchor_id.label);
                      setIsOpen(false);
                      const element = document.getElementById(link.anchor_id.anchor_id);
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                      }
                    }}
                    className="text-white md:text-black hover:text-[#988a7d] text-base block mb-2 transition-colors duration-200 md:flex md:items-center"
                  >
                    <span className="hidden md:inline-block mr-2">{index + 1}.</span>
                    <div dangerouslySetInnerHTML={sanitizeHTML(link.anchor_id.label)} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

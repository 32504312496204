import Video from "@/components/shared/VideoComp/Video";
import { sanitizeHTML } from "@/lib/utils";

interface PatientExperienceData {
  heading: {
    title?: string;
    text?: string;
  };
  videos: {
    videos_id: {
      embed_url?: string;
      embed_title?: string;
    };
  }[];
}

export const fields = [
  "heading.title",
  "heading.text",
  "videos.videos_id.embed_url",
  "videos.videos_id.embed_title",
];

interface PatientExperienceProps {
  data: PatientExperienceData;
}

export default function PatientExperience({ data }: PatientExperienceProps) {
  return (
    <section id="latest-facelift" className="bg-black py-14">
      <div className="max-w-[1080px] mx-auto px-4">
        <h2
          className="text-4xl md:text-5xl text-white text-center font-freightBig mb-12"
          dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || data?.heading?.text || '')}
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {data?.videos?.map((video, index) => (
            <div key={index} className="flex flex-col">
                <iframe
                  loading={"lazy"}
                  title={video?.videos_id?.embed_title}
                  src={video?.videos_id?.embed_url}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  className="size-full"
                />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

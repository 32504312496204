"use client";

import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { getReadableImageUrl } from "@/lib/directus";

interface PartnerLogosSpinData {
  images?: {
    images_id: {
      alt: string;
      image_file: {
        image: {
          id: string;
          filename_download: string;
        };
      };
    };
  }[];
}

export const fields = [
  "images.images_id.alt",
  "images.images_id.image_file.image.id",
  "images.images_id.image_file.image.filename_download",
] as const;

interface PartnerLogosSpinProps {
  data?: PartnerLogosSpinData;
}

export default function PartnerLogosSpin({ data }: PartnerLogosSpinProps) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const images = data?.images?.map(img => ({
    src: getReadableImageUrl(img.images_id.image_file.image.id, img.images_id.image_file.image.filename_download),
    alt: img.images_id.alt || ''
  })) || [];

  return (
    <section
      id="PartnerLogosSpin"
      className="py-8 md:py-12 bg-white"
      ref={sectionRef}
    >
      <div className="max-w-[1080px] mx-auto px-4">
        <div className="relative overflow-hidden">
          <div className="flex will-change-transform">
            <div
              className={`flex shrink-0 items-center translate-gpu ${
                isVisible ? "animate-[slide_40s_linear_infinite]" : ""
              }`}
            >
              {images.map((image, index) => (
                <div key={index} className="shrink-0">
                  <Image
                    src={image.src}
                    alt={image.alt}
                    width={200}
                    height={100}
                    quality={95}
                    className={`h-[100px] max-w-[183px] w-auto object-contain mx-8`}
                  />
                </div>
              ))}
            </div>
            <div
              className={`flex shrink-0 items-center translate-gpu ${
                isVisible ? "animate-[slide_40s_linear_infinite]" : ""
              }`}
            >
              {images.map((image, index) => (
                <div key={`second-${index}`} className="shrink-0">
                  <Image
                    src={image.src}
                    alt={image.alt}
                    width={200}
                    height={100}
                    quality={95}
                    className={`h-[100px] max-w-[183px] w-auto object-contain mx-8`}
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
